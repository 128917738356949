"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const StoreFinderGeneral_1 = require("./store-finder-general/StoreFinderGeneral");
const StoreList_1 = require("./store-list/StoreList");
const common_types_1 = require("@as-react/common-types");
const common_components_1 = require("@as-react/common-components");
const react_redux_1 = require("react-redux");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const StoreAPI_1 = require("../../../api/store/StoreAPI");
const SearchAPI_1 = require("../../../api/search/SearchAPI");
const OsmApi_1 = require("../../../api/open-street-map/OsmApi");
const LocationUtil_1 = require("../../../util/LocationUtil");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const ZendeskWidgetUtil_1 = require("../../../util/ZendeskWidgetUtil");
const common_utils_1 = require("@as-react/common-utils");
const StoreUtil_1 = require("../../../util/StoreUtil");
const StorageKeysConstants_1 = require("../../../constants/StorageKeysConstants");
const LocalStorageUtil_1 = require("../../../util/LocalStorageUtil");
const StoreFinderForm_1 = require("../store-finder-form/StoreFinderForm");
const SessionSelector_1 = require("../../../redux/session/selectors/SessionSelector");
const CustomerSelector_1 = require("../../../redux/customer/selectors/CustomerSelector");
const ProductSelector_1 = require("../../../redux/product/selectors/ProductSelector");
const StoreFinderConstants_1 = require("../../../constants/StoreFinderConstants");
const StoreListLoader_1 = require("./store-list-loader/StoreListLoader");
const BasketSelector_1 = require("../../../redux/basket/selectors/BasketSelector");
const andes_react_1 = require("@yonderland/andes-react");
const react_dom_1 = require("react-dom");
// https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError
var GeolocationPositionErrorCode;
(function (GeolocationPositionErrorCode) {
    GeolocationPositionErrorCode[GeolocationPositionErrorCode["PERMISSION_DENIED"] = 1] = "PERMISSION_DENIED";
    GeolocationPositionErrorCode[GeolocationPositionErrorCode["POSITION_UNAVAILABLE"] = 2] = "POSITION_UNAVAILABLE";
    GeolocationPositionErrorCode[GeolocationPositionErrorCode["TIMEOUT"] = 3] = "TIMEOUT";
})(GeolocationPositionErrorCode || (GeolocationPositionErrorCode = {}));
const initLocation = {
    lat: '',
    lng: '',
};
const SIDEBAR_WIDTH = '512px';
const initLocationSearchResult = {
    location: initLocation,
    locationName: '',
    type: common_types_1.LocationSearchType.DEFAULT,
    state: 'INIT',
};
const StoreFinderPopup = ({ addToBasket, addToFilter, fredhopperSegment, handleClosePopup, setClickAndCollectStoreId, isOpen, selectedSku, urlInfo, type = common_types_1.StoreFinderType.CLICK_AND_COLLECT, disabledAddToBasket = false, title, storeSourceExcluded, allowMultiFascia, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const isClient = (0, cotopaxi_1.useIsClient)();
    const isLoggedIn = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetIsLoggedIn)());
    const profile = (0, react_redux_1.useSelector)((0, CustomerSelector_1.makeGetCustomerProfile)());
    const specialtyType = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetSpecialtyType)());
    const products = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketMappedProductsState)());
    const productInfo = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductInfo)());
    const configuration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(configuration);
    const fictiveWebshop = (0, ConfigurationUtil_1.getFictiveWebShop)(configuration);
    const authenticationConfig = (0, ConfigurationUtil_1.getAuthenticationConfig)(configuration);
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(configuration);
    const isShoplanding = (0, ConfigurationUtil_1.getShoplandingEnabledFlag)(configuration);
    const defaultCountryCode = (0, ConfigurationUtil_1.getDefaultCountryCode)(configuration);
    const countryCodes = (0, ConfigurationUtil_1.getCountryCodes)(configuration);
    const businessUnitId = (0, ConfigurationUtil_1.getBusinessUnitId)(configuration);
    const { isPDPPage } = (0, ConfigurationUtil_1.getPageInfo)(configuration);
    const isSpecialtyTypeProducts = !!specialtyType || (!isPDPPage && !!products.find(product => product.specialtyStoreProduct));
    const isSSEProducts = !!storeSourceExcluded || (!isPDPPage && !!products.find(product => product.sseProduct));
    const [location, setLocation] = (0, react_1.useState)(initLocation);
    const [isLocationBlocked, setIsLocationBlocked] = (0, react_1.useState)(false);
    const [hasUserLocation, setHasUserLocation] = (0, react_1.useState)(false);
    const [locationSearchResult, setLocationSearchResult] = (0, react_1.useState)(initLocationSearchResult);
    const [stores, setStores] = (0, react_1.useState)([]);
    const [isShowStock, setIsShowStock] = (0, react_1.useState)(isSpecialtyTypeProducts && isPDPPage);
    const [valuesForm, setValuesForm] = (0, react_1.useState)(StoreFinderForm_1.initialValuesStoreFinder);
    const [isLoading, setIsLoading] = (0, react_1.useState)(true);
    const [isLocationChecked, setIsLocationChecked] = (0, react_1.useState)(false);
    const [isProfileLocationSearchLoaded, setIsProfileLocationSearchLoaded] = (0, react_1.useState)(false);
    const [geocodeError, setGeocodeError] = (0, react_1.useState)();
    const [errorFeedback, setErrorFeedback] = (0, react_1.useState)();
    const [manualSearch, setManualSearch] = (0, react_1.useState)(false);
    const [userSelectedStore, setUserSelectedStore] = (0, react_1.useState)();
    const [storeInfo, setStoreInfo] = (0, react_1.useState)();
    const [isStoreFinderPopupCTAOpen, setIsStoreFinderPopupCTAOpen] = (0, react_1.useState)(false);
    const typeBasketOrCC = type === common_types_1.StoreFinderType.CLICK_AND_COLLECT || type === common_types_1.StoreFinderType.BASKET;
    const resetToInitialState = () => {
        setStores([]);
        setIsLoading(true);
        setValuesForm(StoreFinderForm_1.initialValuesStoreFinder);
        setIsLocationChecked(false);
        setErrorFeedback(undefined);
        setGeocodeError(undefined);
        setLocationSearchResult(initLocationSearchResult);
        setIsShowStock(isSpecialtyTypeProducts);
    };
    const onPopoverClose = () => {
        resetToInitialState();
        setUserSelectedStore(undefined);
        handleClosePopup();
    };
    const onLocationSearched = (result) => {
        if (isShoplanding) {
            setLocationSearchResult(result);
        }
        else {
            common_utils_1.CookieUtil.set(common_types_1.Cookie.BROWSER_GEOLOCATION, encodeURIComponent(JSON.stringify(result)), { secure: true });
        }
    };
    const checkBrowserLocation = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        setIsLocationChecked(false);
        try {
            const browserLocation = yield LocationUtil_1.default.getLocationFromBrowser();
            setLocation(browserLocation);
            setHasUserLocation(true);
            setIsLocationBlocked(false);
            setIsLocationChecked(true);
        }
        catch (error) {
            const { code } = error;
            if (Number(code) === GeolocationPositionErrorCode.PERMISSION_DENIED) {
                setLocationSearchResult(Object.assign(Object.assign({}, initLocationSearchResult), { state: 'SET' }));
            }
            setIsLocationBlocked(true);
            setIsLocationChecked(true);
        }
    });
    const getShoplandingLocation = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        const storeNumber = LocalStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.SHOP_NUMBER_STORAGE_KEY);
        if (!storeNumber) {
            return;
        }
        const shoplandingInfoResponse = yield StoreAPI_1.default.getShoplandingInfo(servicesEndpoint, defaultRequestParams.market, storeNumber);
        if (shoplandingInfoResponse === null || shoplandingInfoResponse === void 0 ? void 0 : shoplandingInfoResponse.geographic) {
            const tmpLocationSearchResult = {
                location: {
                    lat: shoplandingInfoResponse.geographic.latitude,
                    lng: shoplandingInfoResponse.geographic.longitude,
                },
                locationName: '',
                type: common_types_1.LocationSearchType.DEFAULT,
                state: 'SET',
            };
            onLocationSearched(tmpLocationSearchResult);
            setIsLocationChecked(true);
        }
        else {
            setIsLocationChecked(true);
            setIsLoading(false);
            setErrorFeedback(t('product.check.search.noLocationFound'));
        }
    });
    const parseCookieLocation = (value) => {
        let tmpLocationSearchResult = initLocationSearchResult;
        if (value) {
            const parsed = JSON.parse(decodeURIComponent(value));
            if ((0, common_types_1.isLocationSearchResult)(parsed)) {
                tmpLocationSearchResult = parsed;
            }
            else {
                tmpLocationSearchResult.location = parsed;
            }
        }
        setLocationSearchResult(tmpLocationSearchResult);
    };
    const getAppliedFilters = () => {
        var _a;
        const appliedFilters = ((_a = urlInfo === null || urlInfo === void 0 ? void 0 : urlInfo.filter) === null || _a === void 0 ? void 0 : _a.length)
            ? urlInfo.filter.map(filter => `${filter.filterOn}:${filter.filterValue}`)
            : [];
        return (urlInfo === null || urlInfo === void 0 ? void 0 : urlInfo.defaultFilter) ? appliedFilters.concat(urlInfo.defaultFilter) : appliedFilters;
    };
    const getSortBy = () => {
        var _a;
        if (geocodeError) {
            return 'storeName';
        }
        if (locationSearchResult.type === common_types_1.LocationSearchType.CUSTOM_SEARCH || isShoplanding) {
            return 'distance';
        }
        if (!isLoggedIn) {
            return isLocationBlocked ? 'storeName' : 'distance';
        }
        else {
            return ((_a = profile === null || profile === void 0 ? void 0 : profile.address) === null || _a === void 0 ? void 0 : _a.city) ? 'distance' : isLocationBlocked ? 'storeName' : 'distance';
        }
    };
    const getProfileLocationSearch = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        var _a;
        setIsProfileLocationSearchLoaded(false);
        if ((_a = profile === null || profile === void 0 ? void 0 : profile.address) === null || _a === void 0 ? void 0 : _a.city) {
            const answer = yield OsmApi_1.default.getLocationFromOSM(profile.address.city);
            const sortAnswerByRank = answer
                .filter(place => StoreFinderConstants_1.LOCATION_TYPE.includes(place.type))
                .sort((a, b) => b.importance - a.importance);
            if (sortAnswerByRank.length > 0) {
                const tmpLocationSearchResult = {
                    location: {
                        lat: sortAnswerByRank[0].lat,
                        lng: sortAnswerByRank[0].lon,
                    },
                    locationName: profile.address.city,
                    type: common_types_1.LocationSearchType.SHARE_MY_LOCATION,
                    state: 'SET',
                };
                onLocationSearched(tmpLocationSearchResult);
            }
        }
        setIsProfileLocationSearchLoaded(true);
    });
    const getStoreData = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        var _b, _c;
        setErrorFeedback(undefined);
        setIsLoading(true);
        setStores([]);
        let storeData;
        try {
            const selectedStoreId = !manualSearch
                ? (_b = LocalStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.SELECTED_STORE_STORAGE_KEY)) === null || _b === void 0 ? void 0 : _b.storeId
                : undefined;
            if (type === common_types_1.StoreFinderType.IN_STORE_FILTER || type === common_types_1.StoreFinderType.LOCATOR) {
                storeData = yield StoreAPI_1.default.getClickAndCollectStores(servicesEndpoint, defaultRequestParams, locationSearchResult.location, type === common_types_1.StoreFinderType.IN_STORE_FILTER, !!allowMultiFascia, getSortBy(), selectedStoreId);
            }
            else {
                const productData = (productInfo === null || productInfo === void 0 ? void 0 : productInfo.productId) && isPDPPage
                    ? [
                        {
                            skuId: Number(selectedSku),
                            productId: Number(productInfo.productId),
                            colorId: Number(productInfo.selectedColorId),
                            quantity: 1,
                        },
                    ]
                    : products
                        .filter(product => product.productType === common_types_1.ProductTypes.PRODUCT)
                        .map(product => ({
                        skuId: Number(product.sku),
                        productId: Number(product.productId),
                        colorId: Number(product.colorId),
                        quantity: product.quantity,
                    }));
                storeData = yield StoreAPI_1.default.getClickAndCollectStoresOpeningsHours(servicesEndpoint, defaultRequestParams, businessUnitId, fictiveWebshop, productData, locationSearchResult.location, specialtyType ? false : isShowStock, isPDPPage && isShowStock && (!!specialtyType || isSpecialtyTypeProducts), getSortBy(), isShoplanding, selectedStoreId);
            }
            if (type === common_types_1.StoreFinderType.IN_STORE_FILTER) {
                const productCountPerStore = yield SearchAPI_1.default.getProductCountPerStore(servicesEndpoint, authenticationConfig, fredhopperSegment, urlInfo, isShoplanding, getAppliedFilters());
                if (productCountPerStore.length) {
                    storeData.forEach(store => {
                        var _a;
                        store.itemsCount = (_a = productCountPerStore.find(item => item.storeId === store.storeId)) === null || _a === void 0 ? void 0 : _a.itemCount;
                    });
                }
            }
            setIsLoading(false);
            setStores(storeData);
            const selectedInStorageStoreId = (_c = LocalStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.SELECTED_STORE_STORAGE_KEY)) === null || _c === void 0 ? void 0 : _c.storeId;
            const selectedStore = storeData.find(store => store.storeId === selectedInStorageStoreId);
            if (selectedInStorageStoreId && selectedStore) {
                handleStoreSelect(selectedStore);
            }
            else {
                setUserSelectedStore(undefined);
            }
        }
        catch (error) {
            setErrorFeedback(type === common_types_1.StoreFinderType.BASKET
                ? t('storeFinder.popup.basket.not.in.stock')
                : t('storeFinder.popup.not.in.stock'));
            setIsLoading(false);
            setStores([]);
        }
        setManualSearch(false);
    });
    const getUserAddressFromOSM = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        const osmAddress = yield OsmApi_1.default.getAddressFromOSM(location);
        if (osmAddress.error) {
            setGeocodeError(t('storeFinder.popup.unable.to.geocode'));
            setLocationSearchResult(Object.assign(Object.assign({}, initLocationSearchResult), { state: 'SET' }));
            return;
        }
        const locationName = `${osmAddress.address.road ? osmAddress.address.road : ''}${osmAddress.address.road && (osmAddress.address.town || osmAddress.address.city) ? ', ' : ''}${osmAddress.address.town ? osmAddress.address.town : osmAddress.address.city ? osmAddress.address.city : ''}`;
        const userLocation = {
            location: {
                lat: osmAddress.lat,
                lng: osmAddress.lon,
            },
            locationName,
            type: common_types_1.LocationSearchType.SHARE_MY_LOCATION,
            state: 'SET',
        };
        onLocationSearched(userLocation);
        setValuesForm({ location: locationName });
    });
    const checkLocationByName = (locationName) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        const isPostCode = StoreUtil_1.default.isSearchByPostCode(locationName);
        const osmLocation = yield OsmApi_1.default.getLocationFromOSM(isPostCode ? StoreUtil_1.default.formatPostCode(locationName, defaultCountryCode) : locationName, isPostCode ? defaultCountryCode : countryCodes);
        const osmLocationByImportance = osmLocation
            .filter(place => StoreFinderConstants_1.LOCATION_TYPE.includes(place.type))
            .sort((a, b) => b.importance - a.importance);
        if (osmLocationByImportance.length > 0) {
            return {
                location: {
                    lat: osmLocationByImportance[0].lat,
                    lng: osmLocationByImportance[0].lon,
                },
                locationName,
                type: common_types_1.LocationSearchType.CUSTOM_SEARCH,
                state: 'SET',
            };
        }
        return null;
    });
    const getLocationSearchFromStorage = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        const location = LocalStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.SEARCH_STORE_STORAGE_KEY);
        const locationSearchResult = yield checkLocationByName(location);
        if (!locationSearchResult) {
            return;
        }
        onLocationSearched(locationSearchResult);
        setValuesForm({ location });
        setIsLocationChecked(true);
    });
    const handleSubmitLocation = (values, actions) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        const { location: formLocation } = values;
        if (!formLocation.trim()) {
            return;
        }
        const locationSearchResult = yield checkLocationByName(formLocation);
        if (locationSearchResult) {
            onLocationSearched(locationSearchResult);
            setValuesForm({ location: formLocation });
            setManualSearch(true);
            LocalStorageUtil_1.default.setItem(StorageKeysConstants_1.SEARCH_STORE_STORAGE_KEY, formLocation);
        }
        else {
            actions.setFieldError('location', t('product.check.search.noLocationFound'));
        }
        setGeocodeError(undefined);
    });
    const handleOnStoreClick = (_d) => tslib_1.__awaiter(void 0, [_d], void 0, function* ({ storeId, storeName }) {
        if (typeBasketOrCC) {
            yield StoreAPI_1.default.setClickAndCollectStoreId(servicesEndpoint, defaultRequestParams, storeId);
            if (type === common_types_1.StoreFinderType.CLICK_AND_COLLECT) {
                addToBasket === null || addToBasket === void 0 ? void 0 : addToBasket(1);
            }
            if (type === common_types_1.StoreFinderType.BASKET) {
                setClickAndCollectStoreId === null || setClickAndCollectStoreId === void 0 ? void 0 : setClickAndCollectStoreId(storeId);
            }
        }
        else if (type === common_types_1.StoreFinderType.IN_STORE_FILTER) {
            addToFilter === null || addToFilter === void 0 ? void 0 : addToFilter(storeId, storeName);
        }
        LocalStorageUtil_1.default.setItem(StorageKeysConstants_1.SELECTED_STORE_STORAGE_KEY, { storeId, storeName });
        closeStoreFinderPopup();
    });
    const handleChangeStock = () => setIsShowStock(prevState => !prevState);
    const handleResetValues = () => setValuesForm(StoreFinderForm_1.initialValuesStoreFinder);
    const closeStoreFinderPopup = () => {
        onPopoverClose();
        ZendeskWidgetUtil_1.default.resetZIndex();
    };
    const closeStoreFinderPopupCTA = () => setIsStoreFinderPopupCTAOpen(false);
    const checkBrowserAndProfileLocation = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        if (isShoplanding) {
            yield getShoplandingLocation();
        }
        else {
            if (LocalStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.SEARCH_STORE_STORAGE_KEY)) {
                yield getLocationSearchFromStorage();
            }
            else {
                if (!isLocationChecked) {
                    yield checkBrowserLocation();
                }
                if (!isLocationBlocked && hasUserLocation) {
                    yield getUserAddressFromOSM();
                }
                if (isLoggedIn && isLocationBlocked) {
                    yield getProfileLocationSearch();
                }
            }
        }
    });
    const getStoreInformation = (storeId) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        try {
            const storeInformation = yield StoreAPI_1.default.getStoreInformation(servicesEndpoint, defaultRequestParams, businessUnitId, storeId, 2);
            setStoreInfo(storeInformation);
        }
        catch (_e) { }
    });
    const handleStoreSelect = (store) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        yield getStoreInformation(store.storeId);
        setUserSelectedStore(store);
    });
    const isClickAndCollectCTAVisible = (store) => {
        if (!store) {
            return false;
        }
        const isStoreAvailableOrSSE = Boolean(store.available || store.availableInSSEStore);
        if (!store.selectable && (type === common_types_1.StoreFinderType.CLICK_AND_COLLECT || type === common_types_1.StoreFinderType.BASKET)) {
            return false;
        }
        if (isSSEProducts) {
            return !isSpecialtyTypeProducts ? isStoreAvailableOrSSE : Boolean(isStoreAvailableOrSSE && store.specialtyStore);
        }
        return isSpecialtyTypeProducts ? Boolean(store.specialtyStore) : true;
    };
    (0, cotopaxi_1.useLockBodyScroll)(isOpen);
    (0, react_1.useEffect)(() => {
        if (isOpen) {
            setIsShowStock(isSpecialtyTypeProducts && isPDPPage);
            setIsStoreFinderPopupCTAOpen(isOpen);
        }
    }, [specialtyType, products, isOpen]);
    (0, react_1.useEffect)(() => {
        if (isOpen) {
            checkBrowserAndProfileLocation();
        }
    }, [isOpen, isLoggedIn, profile, hasUserLocation, isLocationBlocked]);
    (0, react_1.useEffect)(() => {
        common_utils_1.CookieUtil.addChangeListener(common_types_1.Cookie.BROWSER_GEOLOCATION, parseCookieLocation);
        return () => {
            common_utils_1.CookieUtil.removeChangeListener(common_types_1.Cookie.BROWSER_GEOLOCATION, parseCookieLocation);
        };
    }, []);
    (0, react_1.useEffect)(() => {
        if (isOpen) {
            if (locationSearchResult.state === 'SET' &&
                isLocationChecked &&
                (!isLoggedIn ||
                    (isLoggedIn && (isProfileLocationSearchLoaded || hasUserLocation)) ||
                    LocalStorageUtil_1.default.getItem(StorageKeysConstants_1.SEARCH_STORE_STORAGE_KEY))) {
                getStoreData();
            }
        }
    }, [
        isOpen,
        locationSearchResult.locationName,
        isLoggedIn,
        isProfileLocationSearchLoaded,
        isLocationChecked,
        isShowStock,
    ]);
    (0, react_1.useEffect)(() => {
        if (type === common_types_1.StoreFinderType.IN_STORE_FILTER && isOpen && stores.length > 0) {
            getStoreData();
        }
    }, [isOpen, type]);
    if (!isClient)
        return null;
    const getCTAContent = (props) => isOpen &&
        isStoreFinderPopupCTAOpen &&
        !!stores.length &&
        isClickAndCollectCTAVisible(userSelectedStore) &&
        Boolean(userSelectedStore === null || userSelectedStore === void 0 ? void 0 : userSelectedStore.storeId) ? ((0, jsx_runtime_1.jsx)(andes_react_1.Sidebar, Object.assign({ isOpen: true, side: "right", align: "bottom", position: "fixed", animation: "none", zIndex: "modal", hasCloseBtn: false, hasBackdrop: false, fullHeight: false }, props, { children: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(andes_react_1.ButtonAlpha, { dataTestId: "popup_and_collect", fullWidth: true, theme: "branded", state: (disabledAddToBasket && type !== common_types_1.StoreFinderType.BASKET) ||
                    (type === common_types_1.StoreFinderType.IN_STORE_FILTER && !(userSelectedStore === null || userSelectedStore === void 0 ? void 0 : userSelectedStore.itemsCount))
                    ? 'disabled'
                    : undefined, text: type === common_types_1.StoreFinderType.IN_STORE_FILTER || type === common_types_1.StoreFinderType.BASKET
                    ? t(`${i18nConstants_1.I18N_NAMESPACES.product}:product.lister.inStore.choose`)
                    : t(`${i18nConstants_1.I18N_NAMESPACES.store}:store.addToBasket`), onClick: () => handleOnStoreClick(userSelectedStore) }) }) }))) : null;
    const getContent = () => ((0, jsx_runtime_1.jsxs)(andes_react_1.Grid, { children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { as: "h2", variant: "headingS", children: title !== null && title !== void 0 ? title : t('storeFinder.popup.title') }), (0, jsx_runtime_1.jsx)(StoreFinderGeneral_1.default, { isShowStock: isShowStock, isShowStockCheckbox: typeBasketOrCC, valuesForm: valuesForm, handleResetValues: handleResetValues, handleSubmitLocation: handleSubmitLocation, handleChangeStock: handleChangeStock, specialtyType: specialtyType, storeFinderType: type, stores: stores }), geocodeError && (0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { text: geocodeError, variant: cotopaxi_1.FeedbackMessageVariant.NEGATIVE }), errorFeedback && ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockEnd: "4", children: (0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { text: errorFeedback, variant: cotopaxi_1.FeedbackMessageVariant.NEGATIVE }) })), isLoading && (0, jsx_runtime_1.jsx)(StoreListLoader_1.StoreListLoader, {}), !!stores.length && !isLoading && ((0, jsx_runtime_1.jsx)(StoreList_1.StoreList, { stores: stores, storeFinderType: type, sortBy: getSortBy(), location: location, isLocationBlocked: isLocationBlocked, handleStoreSelect: handleStoreSelect, userSelectedStoreId: userSelectedStore === null || userSelectedStore === void 0 ? void 0 : userSelectedStore.storeId, selectedStoreInfo: storeInfo, isClickAndCollectCTAVisible: isClickAndCollectCTAVisible }))] }));
    return (0, react_dom_1.createPortal)((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(andes_react_1.Breakpoint, { until: "md", children: [(0, jsx_runtime_1.jsx)(andes_react_1.BottomSheet, { isOpen: isOpen, onClose: closeStoreFinderPopup, padding: "none", children: getContent() }), getCTAContent({ customWidth: { width: '100%' } })] }), (0, jsx_runtime_1.jsxs)(andes_react_1.Breakpoint, { from: "md", children: [(0, jsx_runtime_1.jsx)(andes_react_1.Sidebar, { isOpen: isOpen, onClose: closeStoreFinderPopup, onCloseStart: closeStoreFinderPopupCTA, side: "right", align: "top", position: "fixed", customWidth: { width: SIDEBAR_WIDTH }, hasCloseBtnSpacing: false, zIndex: "modal", children: getContent() }), getCTAContent({ customWidth: { width: SIDEBAR_WIDTH } })] })] }), document.body);
};
exports.default = StoreFinderPopup;
