"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerDetailsPreview = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const CheckoutDeliveryContext_1 = require("../../context/CheckoutDeliveryContext");
const CustomerUtil_1 = require("../../util/CustomerUtil");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const common_queries_1 = require("@as-react/common-queries");
const react_query_1 = require("@tanstack/react-query");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../constants/i18nConstants");
const CustomerDetailsPreview = ({ onEdit }) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const lang = (0, ConfigurationUtil_1.getLang)(aemConfiguration);
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.checkout]);
    const [{ info }] = (0, CheckoutDeliveryContext_1.useDeliveryState)();
    const { getTitles } = (0, common_queries_1.customerQueries)({ servicesEndpoint, defaultRequestParams });
    const { data: customerTitles } = (0, react_query_1.useQuery)(getTitles({ lang }));
    const customerNames = CustomerUtil_1.default.getCustomerNames(info);
    const getCustomerTitle = (id) => {
        var _a;
        if (!customerTitles) {
            return '';
        }
        return ((_a = customerTitles.options.find(title => title.value === id)) === null || _a === void 0 ? void 0 : _a.label) || '';
    };
    const getCustomerDetails = () => `${getCustomerTitle(customerNames.idTitle)} ${customerNames.firstName} ${customerNames.middleName} ${customerNames.lastName}`;
    return ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { backgroundColor: "quiet", padding: "4", children: (0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { gap: "2", contentAlign: "between", itemsAlign: "baseline", wrap: "nowrap", children: [(0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: "subdued", strong: true, children: t('checkout:checkout.delivery.customer.details.title') }), (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: "subdued", children: getCustomerDetails() })] }), (0, jsx_runtime_1.jsx)(andes_react_1.Box, { children: (0, jsx_runtime_1.jsx)(andes_react_1.LinkButton, { text: t('checkout:checkout.delivery.change.customer.details'), onClick: onEdit }) })] }) }));
};
exports.CustomerDetailsPreview = CustomerDetailsPreview;
