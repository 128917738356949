"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerDetailsNLWatcher = void 0;
const react_hook_form_1 = require("react-hook-form");
const react_1 = require("react");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const common_queries_1 = require("@as-react/common-queries");
const react_query_1 = require("@tanstack/react-query");
const CheckoutDeliveryContext_1 = require("../../../context/CheckoutDeliveryContext");
const BuyFormUtil_1 = require("../../../util/BuyFormUtil");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const CustomerDetailsNLWatcher = ({ formProps }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.checkout, i18nConstants_1.I18N_NAMESPACES.general]);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const [{ deliveryCountryId }] = (0, CheckoutDeliveryContext_1.useDeliveryState)();
    const { control, setError, setValue, clearErrors } = formProps;
    const [postalCode, houseNumber] = (0, react_hook_form_1.useWatch)({
        control,
        name: ['postCode', 'houseNumber'],
    });
    const { getAddresses } = (0, common_queries_1.generalQueries)({
        servicesEndpoint,
        defaultRequestParams,
    });
    const { data: addresses, error: addressError } = (0, react_query_1.useQuery)(getAddresses({ countryId: deliveryCountryId, postalCode: postalCode, houseNumber: houseNumber }, {
        enabled: (0, BuyFormUtil_1.isValidAddress)(postalCode, houseNumber, 'NL'),
        retry: false,
    }));
    (0, react_1.useEffect)(() => {
        if (addressError) {
            setError('postCode', t('buy.error.no.results'));
        }
    }, [addressError]);
    (0, react_1.useEffect)(() => {
        if (!addresses || addresses.length === 0) {
            return;
        }
        const firstAddress = addresses[0];
        if (firstAddress.address) {
            setValue('address', firstAddress.address, { shouldValidate: true });
            clearErrors('address');
        }
        if (firstAddress.city) {
            setValue('city', firstAddress.city);
            clearErrors('city');
        }
        if (firstAddress.houseNumberAddition) {
            setValue('houseNumberAddition', firstAddress.houseNumberAddition);
            clearErrors('houseNumberAddition');
        }
    }, [addresses]);
    return null;
};
exports.CustomerDetailsNLWatcher = CustomerDetailsNLWatcher;
