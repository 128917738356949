"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Filter = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const react_1 = require("react");
const FilterContent_1 = require("./FilterContent");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const FiltersMobileOld_1 = require("./filters-mobile/FiltersMobileOld");
const FiltersMobile_1 = require("./filters-mobile/FiltersMobile");
const useABTest_1 = require("../../../hooks/useABTest");
const FilterUtil_1 = require("../../../util/FilterUtil");
const andes_react_1 = require("@yonderland/andes-react");
const Filter = ({ filters, activeFilters, itemCount, removeAllFilters, onFilterItemChange, fredhopperGlossary, filterWizardConfig, urlFilters, isContentTileLister, storeOptions, ccPopupCollapsed, }) => {
    const aemConfiguration = (0, react_1.useContext)(common_components_1.AemConfigurationContext);
    const featureToggles = (0, ConfigurationUtil_1.getFeatureToggles)(aemConfiguration);
    const newMobileFilterDesignABCookie = (0, useABTest_1.useABTest)(common_types_1.ABTestCookie.NEW_MOBILE_FILTER_DESIGN);
    const showNewMobileFilterDesign = (0, FilterUtil_1.isNewMobileFilterDesignEnabled)(featureToggles === null || featureToggles === void 0 ? void 0 : featureToggles.new_mobile_filter_design, Boolean(newMobileFilterDesignABCookie));
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(andes_react_1.Breakpoint, { until: "lg", children: showNewMobileFilterDesign ? ((0, jsx_runtime_1.jsx)(FiltersMobile_1.FiltersMobile, { isContentTileLister: isContentTileLister, itemCount: itemCount, filters: filters, filterWizardConfig: filterWizardConfig, fredhopperGlossary: fredhopperGlossary, onFilterItemChange: onFilterItemChange, removeAllFilters: removeAllFilters, storeOptions: storeOptions, urlFilters: urlFilters, ccPopupCollapsed: ccPopupCollapsed })) : ((0, jsx_runtime_1.jsx)(FiltersMobileOld_1.FiltersMobileOld, { activeFilters: activeFilters, filters: filters, filterWizardConfig: filterWizardConfig, fredhopperGlossary: fredhopperGlossary, isContentTileLister: isContentTileLister, itemCount: itemCount, onFilterItemChange: onFilterItemChange, removeAllFilters: removeAllFilters, storeOptions: storeOptions, urlFilters: urlFilters })) }), (0, jsx_runtime_1.jsx)(andes_react_1.Breakpoint, { from: "lg", children: (0, jsx_runtime_1.jsx)(FilterContent_1.FilterContent, { desktop: true, filters: filters, onFilterItemChange: onFilterItemChange, fredhopperGlossary: fredhopperGlossary, filterWizardConfig: filterWizardConfig, itemCount: itemCount, urlFilters: urlFilters }) })] }));
};
exports.Filter = Filter;
