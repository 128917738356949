"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PickupPointSelected = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_icons_1 = require("@yonderland/andes-icons");
const andes_react_1 = require("@yonderland/andes-react");
const PickupPointUtil_1 = require("../../../../util/PickupPointUtil");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const DeliveryPromiseMessageV2_1 = require("../../../product-detail/delivery-promise-v2/DeliveryPromiseMessageV2");
const CheckoutDeliveryContext_1 = require("../../../../context/CheckoutDeliveryContext");
const PickupPointSelected = () => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.buy]);
    const [{ pickupPoint }, dispatch] = (0, CheckoutDeliveryContext_1.useDeliveryState)();
    const { selectedPickupPoint } = pickupPoint;
    if (!selectedPickupPoint)
        return null;
    const locationName = selectedPickupPoint.locationName;
    const address = selectedPickupPoint.address;
    const houseNumber = selectedPickupPoint.houseNumber;
    const postcode = selectedPickupPoint.postcode;
    const city = selectedPickupPoint.city;
    const organisationName = `${selectedPickupPoint.organisationName} ${PickupPointUtil_1.default.getTranslation(selectedPickupPoint.sanitizedLocationType, selectedPickupPoint.locationType, t)}`;
    const isAlwaysOpen = PickupPointUtil_1.default.isAlwaysOpen(selectedPickupPoint.sanitizedLocationType || selectedPickupPoint.locationType);
    const handleEditPickupPoint = () => {
        dispatch({ type: 'set_is_open', payload: true });
    };
    return ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { backgroundColor: "quiet", padding: "4", children: (0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "1", children: [(0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { gap: "2", contentAlign: "between", itemsAlign: "start", wrap: "nowrap", children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: "neutral", strong: true, children: locationName }), (0, jsx_runtime_1.jsx)(andes_react_1.Box, { children: (0, jsx_runtime_1.jsx)(andes_react_1.LinkButton, { text: t('checkout:checkout.delivery.change.pickuppoint'), onClick: handleEditPickupPoint }) })] }), (0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { gap: "2", itemsAlign: "start", wrap: "nowrap", children: [(0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: "1", children: (0, jsx_runtime_1.jsx)(andes_react_1.IconAlpha, { source: andes_icons_1.MapPinIcon, size: "xs", color: "subdued" }) }), (0, jsx_runtime_1.jsxs)(andes_react_1.TextAlpha, { color: "subdued", children: [organisationName, (0, jsx_runtime_1.jsx)("br", {}), address, " ", houseNumber, (0, jsx_runtime_1.jsx)("br", {}), postcode, " ", city] })] }), isAlwaysOpen && ((0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { gap: "2", itemsAlign: "start", wrap: "nowrap", children: [(0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: "1", children: (0, jsx_runtime_1.jsx)(andes_react_1.IconAlpha, { source: andes_icons_1.ClockIcon, size: "xs", color: "subdued" }) }), (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: "subdued", children: t('buy:buy.delivery.pickuppoint.open.24') })] })), (0, jsx_runtime_1.jsx)(DeliveryPromiseMessageV2_1.default, {})] }) }));
};
exports.PickupPointSelected = PickupPointSelected;
