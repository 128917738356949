"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CountrySelector = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const andes_react_1 = require("@yonderland/andes-react");
const ArrayUtil_1 = require("../../../../util/ArrayUtil");
const CountrySelector = ({ countries, selectedId, loading, lang, changeDeliveryCountry, }) => {
    const items = (0, react_1.useMemo)(() => countries.map(country => {
        var _a, _b;
        return ({
            text: (_b = (_a = country.countryTranslations[lang.toUpperCase()]) === null || _a === void 0 ? void 0 : _a.countryDescription) !== null && _b !== void 0 ? _b : '',
            id: country.countryId,
        });
    }), [countries, selectedId, lang]);
    const handleChangeCountry = (country) => {
        if (Number(country.id) !== selectedId) {
            changeDeliveryCountry(Number(country.id));
        }
    };
    if (!(0, ArrayUtil_1.hasItems)(items) || !selectedId)
        return null;
    return ((0, jsx_runtime_1.jsx)(andes_react_1.SelectAlpha, { name: "country-select", input: { id: 'country-select-input', disabled: loading }, items: items, value: selectedId, onSelect: handleChangeCountry }));
};
exports.CountrySelector = CountrySelector;
